import Service from "./Service";
const baseurl = '/api/historia-toma-muestras';

export default {
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    showByHc(id) {
        return Service.get(`${baseurl}/show-by-hc/${id}`);
    },
    update(obj) {
        return Service.put(`${baseurl}/update`, obj);
    },
    index(params = {}){
        return Service.get(`${baseurl}`, {
            params : params
        });
    }

}